.Register {
    height: 100%;
    overflow-x: hidden;
    background-image: url('../../assets/img/ag_background_wide.png');
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
}

.RegisterBox {
    width: 500px;
    margin: auto;
    margin-top: 1%;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 0px 1px 1px rgb(226, 226, 226);
}

.Register h1 {
    margin: auto;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    width: 100%;
    display: inline-block;
    font-weight: normal;
}

.Register h5 {
    font-size: 12px;
    color: #555555;
    margin: auto;
    margin-bottom: 1em;
}

.Register p {
    font-size: 12px;
    color: #555555;
    margin: 0;
}

.Register a {
    font-size: 12px;
    color: #555555;
    text-decoration: none;
    background-color: transparent;
}

.Register a:hover {
    font-size: 13px;
    cursor: pointer;
    color: #ff9f35;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.Container {
    display: flex;
    justify-content: space-between;
}

.Register label {
    margin-left: 0;
}

.Register Input {
    margin-bottom: 1em;
}

.Register input[type=checkbox] {
    margin-top: 0;
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 1200px) {
    .RegisterBox {
        width: 900px;
    }
    .Register h1 {
        font-size: 3em;
    }
    .Register h5 {
        font-size: 1em;
    }
    .Register p {
        width: 900px;
        font-size: 1.2em;
    }
    .Register Button {
        height: 50px;
        font-size: 1.4em;
    }
    .Register a {
        font-size: 1.2em;
    }
    .Register a:hover {
        font-size: 1.3em;
    }
    .Register label {
        font-size: 1.3em;
    }
    .Register Input {
        height: 30px;
        font-size: 1.2em;
    }
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 980px) {
    .Register {
        background-image: url('../../assets/img/ag_background_middle.png');
    }
    .RegisterBox {
        margin-top: 20px;
    }
}

/* Media queries for big-screen smartphones like the Samsung Note, & small tablets */

@media all and (max-width: 736px) {
    .RegisterBox {
        width: 400px;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .Register {
        background-image: url('../../assets/img/ag_background.png');
    }
    .RegisterBox {
        width: 300px;
        margin-top: 10px;
    }
    .Register h1 {
        font-size: 1.5em;
        margin-bottom: 0;
    }
    .RegisterBox input[type=checkbox] {
        margin-top: 0.2em;
    }
    .Register a {
        font-size: 0.7em;
        margin-top: 1em;
    }
    .Register h5 {
        font-size: 0.6em;
        color: #555555;
        margin-top: 0;
        margin-bottom: 5px;
        text-emphasis: none;
    }
    .Register a:hover {
        font-size: 13px;
        cursor: pointer;
        color: #ff9f35;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
    }
    .Container {
        display: flex;
        justify-content: space-between;
    }
}

/* Media queries for iPhone 5/SE */

@media all and (max-width: 340px) {
    .RegisterBox {
        width: 250px;
        margin-top: 10px;
    }
    .Register p {
        font-size: 0.7em;
        width: 250px;
        margin-bottom: 0.5em;
    }
}