input:focus,
select:focus {
    border: 1px solid rgb(105, 111, 116);
    outline: none;
}

.LayoutContent {
    text-align: left;
    position: fixed;
    top: 12.1%;
    bottom: 40px;
    width: 100%;
    overflow-x: hidden;
    display: inline-block;
}

.Stripe {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    height: 1%;
    display: block;
    position: absolute;
    background-color: #FF9F35;
}

hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/* width */

::-webkit-scrollbar {
    width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #FF9F35;
    border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: rgb(255, 176, 92);
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 1024px) {
    .LayoutContent {
        top: 10.1%;
    }
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 980px) {
    .LayoutContent {
        bottom: 35px;
    }
}

/* Media queries for big-screen smartphones like the Samsung Note, & small tablets */

@media all and (max-width: 736px) {
    .LayoutContent {
        top: 8.1%;
    }
    ::-webkit-scrollbar {
        display: none;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .LayoutContent {
        bottom: 30px;
    }
}

@media all and (max-width: 100px) {
    .LayoutContent {
        display: none;
    }
    .Stripe {
        display: none;
    }
}