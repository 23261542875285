.SettingsContent {
    height: 100%;
    background-image: url('../../assets/img/ag_background_wide.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
}

.Settings {
    overflow-x: hidden;
    position: fixed;
    left: 300px;
    width: 650px;
    font-size: 1em;
}

.SettingsMobile {
    display: none;
}

.Settings button {
    margin-left: 500px;
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 900px) {
    .Settings {
        left: 400px;
        width: 900px;
        font-size: 1.2em;
    }
    .Settings button {
        margin-left: 750px;
    }
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 980px) {
    .SettingsContent {
        background-image: url('../../assets/img/ag_background_middle.png');
    }
    .Settings {
        left: 250px;
        width: 480px;
    }
    .Settings button {
        margin-left: 355px;
    }
}

/* Media queries for big-screen smartphones like the Samsung Note, & small tablets */

@media all and (max-width: 736px) {
    .Settings {
        position: relative;
        width: 95%;
        left: 2.5%;
        font-size: 0.9em;
    }
    .SettingsMobile {
        display: block;
    }
    .SettingsDesktop {
        display: none;
    }
    .SettingsNavigation {
        display: none;
    }
    .DrawerToggle {
        display: flex;
        margin-top: 15px;
        margin-left: 2%;
    }
    .DrawerToggle p {
        margin: 5px;
    }
    .Settings Button {
        margin-bottom: 1em;
        height: 2em;
        margin-left: 77%;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .SettingsContent {
        background-image: url('../../assets/img/ag_background.png');
    }
}

/* Media queries for iPhone 5/SE */

@media all and (max-width: 355px) {
    .Settings button {
        margin-left: 115px;
    }
}