.Footer {
    border-top: 1px solid #dddbd5;
    font-size: 1em;
    position: absolute;
    text-align: center;
    bottom: 0px;
    background: #fff;
    display: flex;
    justify-content: center;
    height: 40px;
    width: 100%;
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 1200px) {
    .Footer {
        height: 60px;
    }
}

/* Media queries for tablets & multi-tasking computer screens*/

@media all and (max-width: 986px) {
    .Footer {
        font-size: 0.8em;
        height: 35px;
    }
}

/* Media queries for smartphones*/

@media all and (max-width: 768px) {
    .Footer {
        font-size: 0.4em;
        height: 30px;
    }
}

@media all and (max-width: 100px) {
    .Footer {
        display: none;
    }
}