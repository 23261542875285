.NoMatch {
    text-align: center;
    background-image: url('../../assets/img/ag_background404_wide.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 1.8em;
}

.NoMatch h1 {
    margin-top: 1%;
    padding: 5px;
}

.NoMatch p {
    margin: 5px;
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 980px) {
    .NoMatch {
        font-size: 1.2em;
        background-image: url('../../assets/img/ag_background404_middle.png');
    }
}

/* Media queries for big-screen smartphones like the Samsung Note, & small tablets */

@media all and (max-width: 736px) {
    .NoMatch {
        font-size: 1.2em;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .NoMatch {
        font-size: 0.8em;
        background-image: url('../../assets/img/ag_background404.png');
    }
}

/* Media queries for iPhone 5/SE */

@media all and (max-width: 340px) {
    .NoMatch {
        font-size: 0.7em;
    }
}