.CartSummary aside {
    float: right;
}

.Modal button {
    display: inline;
    margin-left: 5px;
}

.CartSummary {
    background-color: white;
    border: 1px solid #ccc;
    padding: 1em;
    width: 90%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 10px;
}

.CartSummaryTotalItems {
    color: #666;
    text-align: center;
}

.CartSummarySubtotal,
.CartSummaryTotal {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    clear: both;
    margin: 1em 0;
    overflow: hidden;
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
}

.Total {
    font-weight: bold;
    text-transform: uppercase;
}

.CartSummary label {
    display: none;
}

.CartSummary input[type=checkbox] {
    margin: 0;
    margin-top: 10px;
}

.CartSummary p {
    padding: 0;
    margin: 7px;
}

.CartSummary input[type=email] {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    margin: 10px 0;
}

.CartSummary input:focus {
    border: 1px solid #5D707F;
    outline: none;
}

.CartSummary Button {
    width: 100%;
    height: 3em;
    background-color: #ff9f35;
    border: none;
    color: white;
    letter-spacing: 1px;
    text-transform: uppercase;
    animation: pulse 2s infinite;
    -webkit-animation: pulse 2s infinite;
}

.CartSummary button:hover {
    cursor: pointer;
    box-shadow: 1px 1px 1px 1px #ccc;
    transition: all 0.5s ease-in-out;
    font-size: 0.9em;
}

.CartSummary a {
    color: #ff9f35;
    text-decoration: none;
}

.CartSummary sup {
    font-size: 0.8em;
}


@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 1200px) {
    .CartSummary {
        font-size: 1.2em;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .CartSummaryTotalItems {
        color: #666;
        text-align: center;
        font-size: 1.4em;
    }
}