.ArticleViewer {
    float: right;
    width: 75%;
    background-color: white;
    margin-top: 1px;
}

.ArticleViewer h2 {
    padding: 5px;
    border-bottom: none;
    display: inline-block;
    margin-bottom: 10px;
}

.DrawerToggleFilters {
    display: none;
}

.LoadingMoreSpinner .Spinner {
    font-size: 4px;
    margin: 0;
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 1200px) {
    .ArticleViewer {
        font-size: 1.4em;
    }
}

@media all and (max-width: 768px) {
    .ArticleViewer {
        float: left;
        margin-left: 5%;
        width: 90%;
        overflow: scroll;
    }
    .ArticleViewer h2 {
        border-bottom: none;
        display: inline-block;
        font-size: 1.2em;
    }
}

@media all and (max-width: 768px) {
    .Test {
        display: none;
    }
    .DrawerToggleFilters {
        display: flex;
        width: 100%;
    }
    .DrawerToggleFilters p {
        margin: 5px;
        font-size: 0.8em;
    }
    .ArticleViewer hr {
        margin-left: 3px;
    }
}