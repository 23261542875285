.Filters {
    position: fixed;
    width: 20%;
    left: 1%;
    height: 80%;
}

.Filters h2 {
    padding: 5px;
    padding-left: 0;
    margin-bottom: 10px;
}

.Filters label {
    margin: 0;
    margin-bottom: 1px;
    font-size: 0.8em;
}

.Filters input {
    margin-left: 0;
    margin-bottom: 10px;
}

.Filters select {
    margin-bottom: 10px;
}

.Filters button {
    font-size: 0.8em;
}

.Filters button::after {
    background-color: #ff9f35;
    margin-bottom: 5px;
    right: unset;
}

.Filters input {
    padding: 5px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid lightgray;
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 1200px) {
    .Filters {
        font-size: 1.4em;
    }
    .Filters input,
    .Filters select {
        font-size: 1em;
    }
    .Filters label {
        font-size: 0.9em;
    }
}

@media all and (max-width: 768px) {
    .Filters {
        position: relative;
        width: 90%;
    }
}