.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.NavigationItems a {
    color: black;
    text-decoration: none;
}

.NavigationItems a:hover {
    color: #ff9f35;
    font-size: 1.25em;
    transition: all 0.4s ease-in-out;
}

.NavigationIcon {
    margin: -3px 3px;
}

.NavigationItems sup {
    font-size: 0.6em;
    margin-left: -2px;
}

.NavigationItems p {
    display: inline;
}

#balance {
    color: #ff9f35;
    font-size: 0.8em;
    text-transform: uppercase;
    cursor: pointer;
}

/* Media queries for big computer screens */

@media screen and (min-height: 1000px) and (min-width: 1400px) {
    .NavigationItems {
        font-size: 1.4em;
    }
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 980px) {
    .NavigationItems {
        font-size: 1em;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .NavigationItems {
        font-size: 0.9em;
    }
    .NavigationItems p {
        display: none;
    }

    .NavigationItems a {
        font-size: 0.8em;
    }
    
    .Mobile {
        display: none;
    }
}

/* Media queries for iPhone 5/SE */

@media all and (max-width: 340px) {
    .NavigationItems {
        font-size: 0.6em;
    }
    .NavigationItems sup {
        font-size: 0.4em;
        margin-left: -2px;
    }
}