@import url('https://fonts.googleapis.com/css?family=Open+Sans|Raleway|Roboto');

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', 'Open Sans', 'Roboto', 'Lato', sans-serif;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4 {
  font-family: 'Roboto', sans-serif;
}

p {
  font-family: 'Lato', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
