.OrderHistory {
    margin-top: 10px;
}

.OrderHistory button {
    display: inline;
    margin-left: 0px;
}

.OrderHistory span:hover {
    cursor: pointer;
    color: #FF9F35;
}

.OrderToggle {
    display: flex;
    justify-content: space-between;
}

.OrderToggle button {
    width: 49%;
}

.ReactTable {
    text-align: center;
    background: white;
}

.ReactTable .rt-td {
    white-space: normal !important;
}

.ReactTable button {
    background: #FF9F35 !important;
    margin-bottom: 0 !important;
}

#purchaseError a {
    color: white;
    text-transform: unset;
    font-size: 1em;
}

#purchaseError a:hover {
    font-size: 1.1em;
    transition: all ease-in-out 0.5s;
}

#rePurchaseYes, #rePurchaseNo {
    margin: 10px 15px;
}
@media all and (max-width: 768px) {
    .ReactTable {
        font-size: 0.9em;
    }
}

/* Media queries for smartphones (except iPhone 4) */

@media all and (max-width: 376px) {
    .ReactTable {
        font-size: 0.7em;
        padding: 0;
    }

    .ArticleHistory .rt-td,
    .ArticleHistory .rt-th {
        max-width: 85px !important;
    }

    .ArticleHistory .rt-thead,
    .ArticleHistory .rt-tr,
    .ArticleHistory .rt-tbody {
        min-width: unset !important;
    }

}

/* Media queries for iPhone 4 + 5/SE */

@media all and (max-width: 320px) {
    .ArticleHistory .rt-td,
    .ArticleHistory .rt-th {
        max-width: 75px !important;
    }

}