.Button {
    background-color: #ff9f35;
    border-radius: 0;
    border: none;
    margin-bottom: 10px;
    font-size: 1em;
    line-height: 1.2;
    min-width: 150px;
    height: 40px;
    letter-spacing: 1px;
    color: white;
    display: flex;
    justify-content: center;
}

.Button:disabled,
.Button:disabled:hover {
    cursor: not-allowed;
    background-color: lightgray;
}

.Button:hover,
.Button:focus {
    cursor: pointer;
    box-shadow: 1px 1px 1px 1px #ccc;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    outline: none;
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 980px) {
    .Button {
        min-width: 125px;
        height: 30px;
    }
}

/* Media queries for big-screen smartphones like the Samsung Note, & small tablets */

@media all and (max-width: 736px) {
    .Button {
        min-width: 100px;
        height: 30px;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .Button {
        font-size: 0.8em;
        min-width: 75px;
        height: 25px;
    }
}

/* Media queries for iPhone 5/SE */

@media all and (max-width: 340px) {
    .Button {
        font-size: 0.8em;
        min-width: 75px;
        padding: 0.4em;
    }
}