@import url("https://fonts.googleapis.com/css?family=Raleway:400,700,900");
/* Base styling */

.Search {
    text-align: center;
    overflow: hidden;
    height: 100%;
    background-image: url('../../../assets/img/ag_background_wide.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
}

.InputWrapper {
    display: inline-block;
}

.SearchTool {
    margin: auto;
    margin-top: 1%;
    padding: 20px;
    background: transparent;
    position: relative;
}

.SearchTool h1 {
    width: 500px;
    margin: auto;
    padding: 10px;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: black;
    cursor: default;
}

.Search h3 {
    margin: auto;
}

.SearchTool p {
    width: 500px;
    margin: auto;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    color: black;
    padding: 10px;
    cursor: default;
}

.SearchTool button {
    font-size: 1em;
    z-index: 100;
    margin-left: 230px;
    height: 45px;
    width: 20px;
    border: none;
    background-color: transparent;
    position: absolute;
}

.SearchTool button:focus {
    outline: 0;
}

.SearchTool button:hover {
    opacity: 0.4;
    cursor: pointer;
}

.Search Input {
    width: 500px;
    padding: 12px 24px;
    background-color: transparent;
    transition: transform 250ms ease-in-out;
    font-size: 14px;
    line-height: 18px;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid #575756;
    transition: all 250ms ease-in-out;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    z-index: 80;
}

.Search Input::placeholder {
    color: grey;
    letter-spacing: 1.5px;
}

.Search Input:focus,
.Search Input:hover {
    padding: 12px 0;
    outline: 0;
    border: 1px solid transparent;
    border-bottom: 1px solid #575756;
    border-radius: 0;
    background-position: 100% center;
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 1200px) {
    .SearchTool {
        width: 900px;
    }
    .SearchTool h1 {
        font-size: 4em;
        width: 900px;
    }
    .SearchTool p {
        width: 900px;
        font-size: 2em;
    }
    .SearchTool button {
        margin-left: 400px;
        height: 50px;
        width: 80px;
    }
    .Search Input {
        width: 900px;
        font-size: 1.2em;
    }
}

/* Media queries for tablets, small computer screens & multi-tasking computer screens */

@media all and (max-width: 986px) {
    .Search {
        background-image: url('../../../assets/img/ag_background_middle.png');
    }
}

/* Media queries for smartphones (except iPhone 5) and small tablets*/

@media all and (max-width: 626px) {
    .Search {
        background-image: url('../../../assets/img/ag_background.png');
    }
    .Search h1 {
        font-size: 1.5em;
        width: 300px;
    }
    .Search p,
    .Search h3 {
        font-size: 0.8em;
        width: 300px;
    }
    .SearchTool button {
        margin-left: 125px;
        height: 30px;
    }
    .Search Input {
        width: 300px;
        padding: 4px 10px;
        font-size: 0.6em;
    }
    .Search Input:focus,
    .Search Input:hover {
        padding: 4px 0;
    }
}

/* Media queries for iPhone 5/SE */

@media all and (max-width: 340px) {
    .Search h1 {
        font-size: 1.3em;
        width: 250px;
    }
    .Search p,
    .Search h3 {
        font-size: 0.8em;
        width: 250px;
    }
    .SearchTool button {
        margin-left: 100px;
    }
    .Search Input {
        width: 250px;
    }
}

@media all and (max-height: 500px) {
    .Search {
        background: none;
    }
}