.NavigationItem a {
    text-decoration: none;
    color: black;
    padding: 16px 5px;
}

.NavigationItem a:hover {
    color: #ff9f35;
    font-size: 1.25em;
    transition: all 0.4s ease-in-out;
}