.ChangeName input {
    margin: 2% 0;
    width: 100%;
    display: inline-block;
    font-size: 1em;
}

.ChangeName input:focus {
    outline: none;
    border: 1px solid #5D707F;
}

.ChangeName label {
    margin: 0;
    color: black;
    font-size: 1em;
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 980px) {
    .ChangeName {
        font-size: 0.8em;
    }
}