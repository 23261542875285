.Input2 {
    margin: 10px 0;
    padding: 0.5em;
    width: 100%;
    border-radius: 4px;
    border: 1px solid lightgray;
}

.Input2::placeholder {
    color: rgba(206, 206, 205, 0.8);
}

.Label {
    font-size: 12px;
    color: black;
    text-transform: uppercase;
}

.LabelCheckbox {
    display: inline;
    color: red;
    text-align: left;
}

.Checkbox {
    display: flex;
    font-size: 12px;
    color: #555555;
    left: 0;
}

.Select {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: block;
    font-size: 0.9em;
    height: 34px;
    width: 100%;
}

.Flex {
    display: flex;
}

.Checkbox p {
    margin-left: 0;
}

.Radio {
    display: inline;
}