.Cart p {
    border-bottom: none;
}

.Cart h5 {
    border-bottom: none;
    font-size: 1.2em;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
    width: 76%;
    font-weight: normal;
}

.Cart h1 {
    text-align: center;
}

.Cart {
    border-bottom: none;
    margin-left: 0.7em;
    width: 100%;
}

.SummaryPosition {
    position: fixed;
    left: 65%;
    overflow-x: hidden;
}

.CartHeader {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.CartHeader Button {
    margin-bottom: 0;
    font-size: 0.8em;
    color: black;
    background-color: white;
    text-decoration: underline;
}

.CartHeader Button:hover {
    box-shadow: none;
    color: #ff9f35;
}

.CartItemHeader {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-top: 1em;
    position: relative;
    width: 76%;
}

.CartItemHeader ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.CartItemHeader li {
    color: #111;
    padding: 0.625em 1em;
}

.OrderMobile {
    display: none;
}

.PurchaseMobile {
    display: none;
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 900px) {
    .Cart {
        font-size: 1.2em;
    }

}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 1024px) {
    .Cart {
        font-size: 0.9em;
    }
}

/* Media queries for big-screen smartphones like the Samsung Note, & small tablets */

@media all and (max-width: 780px) {
    .Cart {
        font-size: 0.8em;
    }
    .CartItemHeader,
    .Cart h5 {
        width: 80%;
    }
    .CartHeader {
        font-size: 0.8em;
    }

    .ReturnButtonText {
        display: none;
    }

    .CartHeader Button {
        font-size: 1.6em;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .Cart {
        font-size: 0.7em;
    }
    .CartHeader {
        width: 80%;
    }
    .CartItemHeader {
        font-size: 0.8em;
        width: 95%;
    }
    .Cart h5 {
        width: 95%;
    }
    .OrderMobile {
        display: inline;
    }
    .OrderMobile Button {
        width: 94%;
        font-size: 1.2em;
        text-transform: uppercase;
    }
    .PurchaseMobile {
        display: block;
        margin-top: 1em;
    }
    .SummaryPosition {
        display: none;
    }
}