.Article {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 2px 2px rgb(226, 226, 226);
    position: relative;
    padding: 0;
    height: 160px;
    margin: 5px;
    width: 99%;
    margin-bottom: 10px;
    overflow: hidden;
}

.ArticleImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 238px;
    height: 158px;
    -o-object-fit: cover;
    object-fit: cover;
    background-image: url("../../assets/img/no_image.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.ArticleInfo {
    position: relative;
    margin-left: 238px;
    height: 162px;
    overflow: hidden;
}

.ArticleHeader {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    margin-left: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    height: 2.7em;
}

.ArticleHeader p {
    font-size: 1.2em;
}

.Article h2 {
    max-width: 75%;
    padding: 0;
    margin-block-start: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-bottom: 0;
}

.ArticleSummary {
    margin: 0;
    padding: 0 15px;
    height: 80px;
    white-space: pre-wrap;
    overflow: hidden;
    width: 85%;
}

.ArticleStripe {
    position: absolute;
    bottom: 0;
    height: 4px;
    width: 100%;
    background-color: #ff9f35;
}

.ArticlePublication {
    overflow: hidden;
    padding: 0px 15px;
    margin: 0;
    color: #a2a7ac;
    display: flex;
    justify-content: space-between;
}

.ArticlePublicationDate, .ArticlePublicationName {
    padding-top: 5px;
}

.ArticleButton {
    background: #ff9f35;
    color: #fff;
    border: none;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    letter-spacing: 1px;
    font-size: 1em;
    min-width: 100px;
    height: 30px;
}

.ArticleButton:hover {
    cursor: pointer;
    box-shadow: 1px 1px 1px 1px #ccc;
    transition: all 0.2s;
}

.ArticleButtonMobile {
    display: none;
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 1200px) {
    .Article {
        height: 233px;
        font-size: 1em;
    }
    .ArticleImg {
        width: 350px;
        height: auto;
    }
    .ArticleInfo {
        margin-left: 350px;
        height: 233px;
    }
    .Article h2 {
        font-size: 1.1em;
    }
    .ArticleSummary {
        font-size: 0.8em;
        height: 130px;
    }
    .ArticleButton {
        height: 40px;
        font-size: 1em;
        min-width: 120px;
    }
    
}

/* Media queries for multi-tasking computer screens */

@media all and (max-width: 968px) {
    .ArticleImg {
        width: 175px;
    }
    .ArticleInfo {
        margin-left: 175px;
    }
    .ArticleButton {
        font-size: 0.9em;
    }
}

/* Media queries for iPad & other tablets */
@media all and (max-width: 780px) {
    .Article {
        height: 128px;
    }
    .ArticleImg {
        width: 200px;
        height: 128px;
    }
    .ArticleInfo {
        margin-left: 200px;
        height: 131px;
    }
    .ArticleHeader{
        padding: 0 10px;
        height: 2.2em;
    }

    .ArticleHeader p {
        font-size: 0.8em;
    }

    .ArticlePublication {
        padding: 5px 10px;
    }
    .Article h2 {
        font-size: 0.8em;
    }
    .ArticleSummary {
        font-size: 0.7em;
        padding: 0 10px;
        line-height: 15px;
        width: 80%;
        height: 5.5em;
    }
    .ArticlePublicationName,
    .ArticlePublicationDate,
    .ArticlePublicationType {
        font-size: 0.5em;
    }

    .ArticleButton {
        height: 20px;
        font-size: 0.6em;
        padding: 0;
        min-width: 90px;
    }
    .ArticleStripe {
        margin-bottom: 0.1em;
    }
}

/* Media queries for mobile phones*/
@media all and (max-width: 768px) {
    .Article {
        height: 100px;
        width: 98%;
    }
    .ArticleImg {
        width: 60px;
        height: 80px;
        background-image: url("../../assets/img/no_image_mobile.jpg");
    }
    .ArticleInfo {
        margin-left: 60px;
        height: 103px;
    }
    .ArticleHeader {
        padding: 0 5px;
        height: 1.2em;
    }

    .ArticleHeader p{
        margin: 2px;
    }
    
    .Article h2 {
        font-size: 0.7em;
        margin-bottom: 0.3em;
    }
    .ArticleSummary {
        font-size: 0.6em;
        padding: 0 5px;
        height: 6.5em;
    }
    .ArticlePublication {
        padding: 0 5px;
        margin-bottom: 0;
        margin-top: 0;
    }
    .ArticleButton {
        display: none;
    }
    .ArticleButtonMobile {
        background: #ff9f35;
        position: absolute;
        top: 80px;
        color: #fff;
        height: 20px;
        border: none;
        overflow: hidden;
        cursor: pointer;
        font-size: 0.8em;
        width: 60px;
        display: block;
    }
}