.Home {
    text-align: center;
    overflow: hidden;
    height: 100%;
    background-image: url('../../assets/img/ag_background_wide.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
}

.Home h1 {
    font-size: 3.2em;
}

.Home p {
    font-size: 1.2em;
    width: 400px;
    margin: auto;
}

.HomeContainer {
    width: 600px;
    margin: auto;
    padding: 20px;
}

.Home button {
    background-color: #ff9f35;
    border-radius: 4px;
    width: 75%;
    margin: auto;
    margin-bottom: 30px;
    text-transform: uppercase;
    margin-top: 20px;
    -webkit-animation: heartBeat 10s infinite;
    animation: heartBeat 10s infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-delay: 2s;
    -webkit-animation-delay: 2s;
}

.Home button:hover {
    transform: scale(1.1);
}

.LoginLink {
    color: rgb(105, 111, 116);
    text-decoration: none;
    font-size: 0.9em;
}

.LoginLink:hover {
    color: #ff9f35;
    font-size: 1em;
    transition: all 0.5s ease-out;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes heartBeat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    5% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    7% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    10% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    12% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes heartBeat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    5% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    7% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    10% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    12% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.heartBeat {
    -webkit-animation-name: heartBeat;
    animation-name: heartBeat;
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 1200px) {
    .HomeContainer {
        width: 900px;
    }
    .Home h1 {
        font-size: 4em;
    }
    .Home p {
        width: 900px;
        font-size: 2em;
    }
    .Home Button {
        height: 50px;
        font-size: 1.4em;
    }
    .Home a {
        font-size: 1.2em;
    }
    .Home a:hover {
        font-size: 1.3em;
    }
}

/* Media queries for big-screen smartphones like the Samsung Note, & small tablets */

@media all and (max-width: 736px) {
    .HomeContainer {
        width: 400px;
        margin: auto;
    }
    .Home h1 {
        font-size: 2em;
    }
    .Home p {
        font-size: 0.8em;
        width: 400px;
    }
    .Home button {
        height: initial;
        font-size: 0.8em;
    }
    .Home a {
        font-size: 0.8em;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .Home {
        background-image: url('../../assets/img/ag_background.png');
    }
    .HomeContainer {
        width: 300px;
    }
    .Home h1 {
        font-size: 1.5em;
    }
    .Home p {
        width: 300px;
    }

    .Home button {
        height: 30px;
    }
}

/* Media queries for iPhone 5/SE */

@media all and (max-width: 340px) {
    .HomeContainer {
        width: 250px;
    }
    .Home h1 {
        font-size: 1.2em;
    }
    .Home p {
        font-size: 0.7em;
        width: 250px;
    }
    .Home Button {
        padding: 0.7em;
        margin-bottom: 1em;
    }
}