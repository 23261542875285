.SettingsNavigation {
    overflow-x: hidden;
    position: fixed;
    top: 15%;
    bottom: 5.5%;
    left: 5px;
    overflow-x: hidden;
    width: 300px;
}

.SettingsNavigation p{
    display: inline;
}

.SettingsNavigation a {
    color: black;
    text-decoration: none;
    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
}

.Icon {
    margin: -3px 3px;
}

.SettingsNavigation a:hover,
.SettingsNavigation a:active,
.SettingsNavigation a.active {
    color: #ff9f35;
}
/* Media queries for tablets & small computer screens */

@media all and (max-width: 1024px) {
    .SettingsNavigation {
        top: 12.1%;
    }

    .SettingsNavigation {
        width: 240px;
    }
}