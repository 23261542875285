.SideDrawerNavigation span {
    font-size: 1.2em;
}

.SideDrawerNavigation hr {
    width: 90%;
    margin: 1em 0;
    color: #ccc;
}

.SideDrawerNavigation li {
    list-style-type: none;
}


.SideDrawerNavigation a {
    color: black;
    text-decoration: none;
    font-size: 1em;
}

.SideDrawerNavigation p {
    text-transform: uppercase;
    color: #FF9F35;
    font-size: 0.9em;
}

.NavigationLogo {
    position: relative;
    height: 70px;
    width: auto;
    margin-top: 1em;
}
