.Order {
    height: 100%;
    overflow-x: hidden;
    background-image: url('../../assets/img/ag_background_wide.png');
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
}

.Order Button {
    margin: auto !important;
}

.OrderBox {
    text-align: center;
    width: 600px;
    margin: auto;
    margin-top: 1%;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 0px 1px 1px rgb(226, 226, 226);
}


/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 1200px) {
    .OrderBox {
        width: 900px;
    }
    .Order h1 {
        font-size: 3em;
    }
    .Order h5 {
        font-size: 1em;
    }
    .Order p {
        width: 900px;
        font-size: 1.2em;
    }
    .Order label {
        font-size: 1.3em;
    }
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 980px) {
    .Order {
        background-image: url('../../assets/img/ag_background_middle.png');
    }
    .OrderBox {
        margin-top: 20px;
    }
}

/* Media queries for big-screen smartphones like the Samsung Note, & small tablets */

@media all and (max-width: 736px) {
    .OrderBox {
        width: 400px;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .Order {
        background-image: url('../../assets/img/ag_background.png');
    }
    .OrderBox {
        width: 300px;
        margin-top: 10px;
    }
    .Order h1 {
        font-size: 1.5em;
        margin-bottom: 0;
    }

    .Order h5 {
        font-size: 0.6em;
        color: #555555;
        margin-top: 0;
        margin-bottom: 5px;
        text-emphasis: none;
    }
}

/* Media queries for iPhone 5/SE */

@media all and (max-width: 340px) {
    .OrderBox {
        width: 250px;
        margin-top: 10px;
    }
    .Order p {
        font-size: 0.7em;
        width: 250px;
        margin-bottom: 0.5em;
    }
}