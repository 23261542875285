.Toolbar {
    height: 12%;
    width: 100%;
    position: fixed;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    vertical-align: baseline;
    box-shadow: 0px 0.2px 1px 1px rgb(226, 226, 226);
}

.DrawerToggleToolbar {
    display: none;
}


/* Media queries for tablets & small computer screens */

@media all and (max-width: 1024px) {
    .Toolbar {
        height: 10%;
    }
}

/* Media queries for smartphones (except iPhone 5/SE) & small tablets */
@media all and (max-width: 736px) {
   .Toolbar {
       height: 8%;
   }
}

/* Media queries for smartphones (except iPhone 5) and small tablets*/
@media all and (max-width: 768px) {
    .DrawerToggleToolbar {
        display: inline;
        color: white;
    }
    
}

/* Media queries for iPhone 5/SE */
@media all and (max-width: 340px) {
      
}

@media all and (max-width: 100px) {
    .Toolbar {
      display: none;
    }
    
  }