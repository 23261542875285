.Cart {
    background-color: #FFFFFF;
    position: relative;
    overflow: hidden;
    width: 75%;
    float: left;
}

.Cart h3 {
    margin: 0;
    padding: 0 0.2em;
    font-size: 1.3em;
}

.Product {
    position: relative;
    padding: 0;
    margin-bottom: 10px;
    overflow: hidden;
    border-bottom: 1px solid #ccc;
    padding: 0.5em 0;
}

.ProductImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 238px;
    height: 158px;
    -o-object-fit: cover;
    object-fit: cover;
    background-image: url("../../assets/img/no_image.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.ProductInfo {
    position: relative;
    margin-left: 238px;
    overflow: hidden;
}

.ProductHeader {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    margin-left: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.7em;
    padding-right: 0;
}

.ProductHeader p {
    font-size: 1.2em;
}

.Product h2 {
    max-width: 75%;
    padding: 0;
    margin-block-start: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-bottom: 0;
}

.ProductSummary {
    margin: 0;
    padding: 0 15px;
    height: 80px;
    white-space: pre-wrap;
    overflow: hidden;
    width: 80%;
}

.ProductPublication {
    overflow: hidden;
    padding: 0px 15px;
    margin: 0;
    color: #a2a7ac;
    display: flex;
    justify-content: space-between;
    padding-right: 0;
}

.ProductPublicationDate,
.ProductPublicationName {
    padding-top: 5px;
}

.BasketButton {
    color: #fff;
    height: 30px;
    border: none;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    letter-spacing: 1px;
    font-size: 1em;
    min-width: 100px;
    background-color: lightgrey;
    outline: none;
}

.BasketButton:hover {
    background-color: #ff9f35;
    transition: all ease-in 0.5s;
    cursor: pointer;
}

.BasketButtonMobile {
    display: none;
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 1200px) {
    .ProductSummary {
        height: 90px;
    }
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 1024px) {
    .Basket {
        font-size: 0.9em;
    }
    .BasketButton {
        margin-top: 70px;
        font-size: 1em;
        background-color: #ff9f35;
    }
    .ProductImg {
        width: 200px;
        height: 128px;
    }
}

/* Media queries for multi-tasking computer screens */

@media all and (max-width: 968px) {
    .ProductImg {
        width: 175px;
        height: 128px;
    }
}

/* Media queries for big-screen smartphones like the Samsung Note, & small tablets */

@media all and (max-width: 780px) {
    .BasketButton {
        margin-top: 15px;
        font-size: 1.1em;
    }
    .ProductInfo {
        margin-left: 116px;
    }
    .ProductSummary {
        height: 50px;
    }
    .ProductImg {
        width: 116px;
        height: 128px;
        background-position: center;
        background-size: 125%;
    }
    .ProductPublicationDate,
    .ProductPublicationName {
        padding-top: 20px;
    }

    .Cart {
        width: 78%;
    }
}

/* Media queries for mobile phones*/

@media all and (max-width: 768px) {
    .Cart {
        width: 98%;
    }
    .Product {
        height: 100px;
        width: 97%;
        margin-bottom: 5px;
    }
    .ProductImg {
        width: 60px;
        height: 80px;
        background-image: url("../../assets/img/no_image_mobile.jpg");
    }
    .ProductInfo {
        margin-left: 60px;
    }
    .ProductHeader {
        padding: 0 5px;
    }
    .ProductHeader p {
        margin: 4px;
    }
    .Product h2 {
        margin-bottom: 0.3em;
    }
    .ProductSummary {
        padding: 0 5px;
        margin-bottom: 1em;
        height: 7em;
    }
    .ProductPublication {
        padding: 0 5px;
    }
    .BasketButton {
        display: none;
    }
    .BasketButtonMobile {
        background: #ff9f35;
        position: absolute;
        top: 80px;
        color: #fff;
        border: none;
        overflow: hidden;
        cursor: pointer;
        font-size: 0.8em;
        display: block;
        width: 60px;
        height: 20px;
    }
}

/* Media queries for iPhone 5/SE */

@media all and (max-width: 355px) {}