.Legal {
    margin: auto;
    text-align: center;
    font-size: 90%;
    height: 100%;
    width: 50%;
}

.Legal p, h4 {
    text-align: justify;
}

.Legal p {
    margin: 0;
}

.Legal h4 {
    margin-bottom: 0;
}

.Legal h1 {
    margin-bottom: 1.2em;
}

.Legal ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    list-style: square outside;
}

.Legal ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    list-style-type: decimal;
}

.Legal li {
    padding: 1px 0px;
}

.Legal span {
    text-decoration: underline;
    font-size: 85%;
}

.Contact {
    padding: 5px 0px;
}

.Contact li {
    padding: 1px 0px;
    list-style-type: none;
}

#about, #contact, #media {
    overflow-x: hidden;
}

#media a {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 12px 30px;
    border: none;
    background: #FF9F35;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
}
#media a:hover,
#media a:focus {
    cursor: pointer;
    box-shadow: 1px 1px 1px 1px #ccc;
    transition: all 0.2s;
}

@media all and (max-width: 768px) {
    .Legal {
        width: 90%;
        font-size: 0.9em;
    }

    .Legal p, h4, h5 {
        text-align: left;
    }
}
