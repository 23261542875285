.Modal {
    position: fixed;
    text-align: center;
    z-index: 5000;
    width: 70%;
    background: #fff;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 2px 2px rgb(117, 117, 117);
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.5s ease-out;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}

@media all and (max-width: 768px) {
    .Modal {
        font-size: 0.9em;
    }
}