.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 1%;
    z-index: 250;
    background-color: white;
    padding-left: 1em;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    box-shadow: 2px 0px 0px 0px rgb(117, 117, 117);
}
.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-101%);
}

.SideDrawer img {
    margin-top: 0 !important;
    height: 50% !important;
}
