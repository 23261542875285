.Logout {
    text-align: center;
    overflow: hidden;
    background-image: url('../../assets/img/ag_background_logout.gif');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;
    font-size: 1.2em;
}

.LogoutTime {
    display: flex;
    justify-content: center;
}

/* Media queries for big computer screens */

@media screen and (min-width: 1900px) and (min-height: 1200px) {
    .Logout {
        font-size: 1.8em;
    }
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 980px) {
    .Logout {
        background-size: 200%;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .Logout {
        font-size: 0.7em;
        background-size: 300%;
    }
}

/* Media queries for iPhone 5/SE */

@media all and (max-width: 340px) {
    .Logout {
        font-size: 0.6em;
    }
}