.Logo {
    background-color: transparent;
    padding: 0;
    height: 80%;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: auto;
}

.Logo img {
    height: 90%;
    margin-top: 5px;
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 1024px) {
    .Logo {
        height: 80%;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .Logo img {
        height: 30%;
        margin-top: 20px;
    }

    #AGLogo {
        height:80% !important;
        margin-top: 5px !important;
    }
}