.SetPasswordContent {
    position: fixed;
    top: 12%;
    bottom: 5.5%;
    left: 0px;
    right: 0px;
    overflow-x: hidden;
    background-image: url('../../assets/img/ag_background_wide.png');
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
}

.SetPasswordBox {
    width: 500px;
    margin: auto;
    margin-top: 1%;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 0px 1px 1px rgb(226, 226, 226);
}

.SetPasswordBox h1 {
    margin: auto;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    width: 100%;
    display: inline-block;
    font-weight: normal;
}

.SetPasswordBox h5 {
    font-size: 12px;
    color: #555555;
    margin: auto;
    margin-bottom: 1em;
}

/* Media queries for tablets & small computer screens */

@media all and (max-width: 980px) {
    .SetPasswordContent {
        background-image: url('../../assets/img/ag_background_middle.png');
    }
}

/* Media queries for big-screen smartphones like the Samsung Note, & small tablets */

@media all and (max-width: 736px) {
    .SetPasswordBox {
        width: 400px;
    }
}

/* Media queries for smartphones (except iPhone 5) */

@media all and (max-width: 768px) {
    .SetPasswordContent {
        background-image: url('../../assets/img/ag_background.png');
    }
    .SetPasswordBox {
        width: 300px;
    }
    .SetPasswordBox h1 {
        font-size: 1.2em;
    }
    .SetPasswordBox h5 {
        font-size: 0.6em;
    }
    .SetPasswordBox Button {
        font-size: 0.7em;
        padding: 4px;
    }
}

/* Media queries for iPhone 5/SE */

@media all and (max-width: 340px) {
    .SetPasswordBox {
        width: 250px;
    }
    .SetPasswordBox h1 {
        font-size: 1em;
    }
}