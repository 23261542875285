.DrawerToggle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    padding-bottom: 1px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #ff9f35;
    margin-left: 3px;
    margin-bottom: 5px;
    color: white;
}
