.FooterItem {
    margin: 0 0.8em;
    display: inline-block;
}

.FooterItem a {
    color: rgb(134, 134, 134);
    text-decoration: none;
    cursor: pointer;
}

.FooterItem a:hover {
    color: rgb(180, 180, 180);
    text-decoration: none;
}